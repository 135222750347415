import { Link, useLocation } from "react-router-dom";
import { ReactComponent as Logo } from "../../svg/logo.svg";
import { ReactComponent as Instagram } from "../../svg/instagram.svg";
import { ReactComponent as ArrowRight } from "../../svg/chevron-right.svg";
import { ReactComponent as HamburgerMenu } from "../../svg/hamburger-menu.svg";
import { ReactComponent as Close } from "../../svg/close.svg";
import { useEffect, useState } from "react";
import { useSpring, animated } from "react-spring";
import { Spring, animated as anim } from "react-spring/renderprops";
import Cookies from "js-cookie";
// @ts-ignore
import Measure from "react-measure";
import useContent, { getCookie } from "../../useContent";

function ListCollection(props: { hideMenu: () => void }) {
  const [show, setShow] = useState(false);
  const lang = getCookie("lang");
  const content = useContent("/api/collections/get/product_collections");
  const onHdeMenu = () => {
    props.hideMenu();
    setShow(false);
  };
  return (
    <Measure offset margin>
      {({ measureRef, contentRect: { offset } }: any) => (
        <div className="overflow-auto">
          <button onClick={() => setShow(!show)} className="block font-thin">
            {lang ? "Koleksi" : "Collection"}
          </button>
          <Spring
            native
            from={{ height: 0 }}
            to={{ height: show ? offset.height : 0 }}
          >
            {(style: any) => (
              <anim.div style={{ overflow: "hidden", ...style }}>
                <div
                  ref={measureRef}
                  className="flex flex-col py-15 overflow-auto font-light text-21 md:text-21"
                >
                  <Link
                    to="/all-collections"
                    onClick={onHdeMenu}
                    className="mb-10"
                  >
                    {lang ? "Semua" : "All"}
                  </Link>
                  {(content.data?.entries || []).map((d: any, i: number) => (
                    <Link
                      key={i}
                      onClick={onHdeMenu}
                      to={"/collections/" + d._id}
                      className="mb-10"
                    >
                      {d.name}
                    </Link>
                  ))}
                </div>
              </anim.div>
            )}
          </Spring>
        </div>
      )}
    </Measure>
  );
}

export default function MainLayout(propsData: any) {
  const [showMenu, setShowMenu] = useState(false);
  const location = useLocation();
  const [props, set] = useSpring(() => ({
    opacity: 0,
  }));

  const lang = getCookie("lang");

  useEffect(() => {
    set({ opacity: showMenu ? 1 : 0 });
    if (showMenu) {
      window.scrollTo(0, 0);
      document.getElementsByTagName("body")[0].classList.add("show-main-menu");
    } else {
      document
        .getElementsByTagName("body")[0]
        .classList.remove("show-main-menu");
    }
  }, [showMenu]);

  useEffect(() => {
    setShowMenu(false);
  }, [location.pathname]);

  const hideMenu = () => {
    setShowMenu(false);
  };

  const setLang = (val: string) => {
    Cookies.set("lang", val, { secure: true, sameSite: "strict" });
    window.location.reload();
  };

  return (
    <>
      <div className="relative z-40">
        <div className="flex items-center justify-between pt-10 pl-20 pr-20 header md:pt-15 md:pl-60 md:pr-60">
          <Link to="/">
            <Logo className={"w-100 md:w-140 logo"} />
          </Link>
          <button onClick={() => setShowMenu(!showMenu)}>
            {showMenu ? (
              <Close className="w-20 md:w-25" />
            ) : (
              <HamburgerMenu className={"w-20 md:w-25 menu"} />
            )}
          </button>
        </div>
      </div>

      <div className="-mt-70">{propsData.children}</div>
      <div className="w-full bg-softGrey md:pt-70 md:pb-70 p-30 md:p-0">
        <div className="container">
          <div className="items-center justify-between md:flex">
            <div className="font-normal text-center md:font-light md:text-left text-12 md:text-18">
              {lang ? "Kenali Kami!" : "Get to know us!"}
            </div>
            <div className="relative flex items-center w-full md:w-10/12">
              <input
                placeholder={lang ? "Email Anda" : "Your email here"}
                className="w-full pt-20 pb-20 font-normal bg-transparent border-b border-black rounded-none outline-none appearance-none md:font-light text-14"
              />
              <span className="absolute right-0 svg-black">
                <ArrowRight />
              </span>
            </div>
          </div>
          <div className="flex items-start justify-between mt-120">
            <div className="w-6/12 font-normal md:font-light md:w-auto text-12 md:text-18">
              {!lang ? "Head Office" : "Kantor Pusat"}
            </div>
            <div className="relative items-start justify-between w-10/12 font-normal md:font-light md:flex">
              <div className="text-12 md:text-18">
                SMK NU Banat Kudus <br />
                Jl. KHM. Arwani Amin Kajan Krandon, <br />
                Kudus, Jawa Tengah 59314 <br />
              </div>
              <div className="flex mt-30 md:mt-0">
                <a
                  href="https://www.instagram.com/officialzelmira/"
                  target="_blank"
                  className="inline-block px-10"
                >
                  <Instagram />
                </a>
                <a
                  href="https://www.youtube.com/channel/UCOm_ao6gb5xcn0QtpJWf9OA"
                  target="_blank"
                  className="inline-block px-10"
                >
                  <svg
                    width={27}
                    height={19}
                    viewBox="0 0 27 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M26.4365 2.96799C26.2828 2.39327 25.9814 1.86915 25.5626 1.4481C25.1438 1.02705 24.6221 0.723831 24.0499 0.56879C21.9435 1.15413e-07 13.5 0 13.5 0C13.5 0 5.05647 -1.15413e-07 2.95011 0.565764C2.37763 0.720303 1.85572 1.02335 1.4368 1.44448C1.01788 1.86561 0.716703 2.38999 0.563504 2.96497C-1.14952e-07 5.0828 0 9.5 0 9.5C0 9.5 -1.14952e-07 13.9172 0.563504 16.032C0.873884 17.1998 1.78996 18.1196 2.95011 18.4312C5.05647 19 13.5 19 13.5 19C13.5 19 21.9435 19 24.0499 18.4312C25.2131 18.1196 26.1261 17.1998 26.4365 16.032C27 13.9172 27 9.5 27 9.5C27 9.5 27 5.0828 26.4365 2.96799ZM10.8181 13.5541V5.44586L17.8092 9.46975L10.8181 13.5541Z"
                      fill="#000000"
                    />
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <animated.div
        style={{
          ...props,
          pointerEvents: showMenu ? "auto" : "none",
        }}
        className="fixed top-0 bottom-0 left-0 right-0 z-30 flex bg-center bg-cover bg-blue"
      >
        <div
          className="md:w-6/12"
          style={{
            backgroundImage: `url('/img/menu-bg.jpg')`,
            backgroundSize: "cover",
            backgroundPosition: "top center",
          }}
        ></div>
        <div className="flex items-center w-full font-thin text-white md:w-6/12 pt-150 pb-80 md:pb-150 bg-blue text-48 md:text-56">
          <div className="flex flex-col flex-grow h-full">
            <div className="flex-grow pl-20 md:pl-120">
              <ListCollection hideMenu={hideMenu} />
              <Link className="block" onClick={hideMenu} to="/about">
                {lang ? "Tentang Kami" : "About"}
              </Link>
              <Link className="block" onClick={hideMenu} to="/press">
                Press
              </Link>
              <Link className="block" onClick={hideMenu} to="/contact">
                {lang ? "Hubungi Kami" : "Contact"}
              </Link>
            </div>
            <div className="relative flex flex-col pl-20 font-normal md:font-light md:pl-120 text-18">
              <a
                href="https://www.instagram.com/officialzelmira/"
                target="_blank"
                className="mb-10"
              >
                Instagram
              </a>
              <a
                href="https://www.youtube.com/channel/UCOm_ao6gb5xcn0QtpJWf9OA"
                className="mb-10"
              >
                Youtube
              </a>
              <div className="absolute flex top-30 right-60">
                <button
                  onClick={() => setLang("")}
                  className={`mr-10 ${
                    lang === "" ? "font-bold" : "font-light"
                  }`}
                >
                  EN
                </button>
                <div>|</div>
                <button
                  onClick={() => setLang("id")}
                  className={`ml-10 ${
                    lang === "id" ? "font-bold" : "font-light"
                  }`}
                >
                  ID
                </button>
              </div>
            </div>
          </div>
        </div>
      </animated.div>
    </>
  );
}

import { useEffect } from "react";
import useContent, { imageUrl } from "./useContent";

export default function Press() {
  const content: any = useContent("/api/collections/get/press");
  useEffect(() => {
    document.getElementsByTagName("body")[0].className = "logo-dark-menu-dark";
  }, []);
  if (content.loading && !content.data) {
    return null;
  }

  return (
    <div className="pt-150">
      <div className="container">
        <div className="font-normal md:font-light text-center text-18 md:text-56 mb-60">
          Press
        </div>
        <div className="text-center grid md:text-left md:grid-cols-3 md:gap-20 pl-50 pr-50 md:pr-0 md:pl-0">
          {(content.data?.entries || []).map((d: any, i: number) => (
            <div key={i}>
              <a href={d.link} className="inline-block" target="_blank">
                <img
                  src={imageUrl(d.image.path)}
                  {...d.image.meta}
                  width="100%"
                />
              </a>
              <div className="pb-5 font-normal md:pt-20 md:font-light mt-15 md:mt-0 text-12 md:text-18">
                {d.date}
              </div>
              <div className="font-normal text-18 md:text-32 mb-50 md:mb-70">
                {d.title}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

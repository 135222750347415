import { useEffect } from "react";
import { ReactComponent as Right } from "./svg/chevron-right-black.svg";
import useContent, { getCookie, imageUrl } from "./useContent";

export default function About() {
  const content: any = useContent("/api/singletons/get/page_about", {
    body: JSON.stringify({}),
  });
  const awards: any = useContent("/api/collections/get/awards");
  const lang = getCookie("lang");

  useEffect(() => {
    document.getElementsByTagName("body")[0].className =
      "logo-white-menu-white";
  }, []);

  if (content.loading && !content.data) {
    return <div className="min-h-screen" />;
  }

  return (
    <div>
      <div
        className="relative flex items-center justify-center bg-center bg-cover h-400 md:h-screen"
        style={{
          backgroundImage: `url(${imageUrl(content.data.banner.path)})`,
        }}
      />
      <div className="text-center text-white bg-blue py-30 pl-30 pr-30 md:pl-0 md:pr-0 md:pt-120 md:pb-120">
        <div className="container">
          <div
            className="ml-auto mr-auto font-normal md:font-light text-18 md:text-56 mb-30 md:mb-60"
            dangerouslySetInnerHTML={{ __html: content.data.section_1_title }}
          />
          <div
            className="ml-auto mr-auto font-normal md:font-light text-12 md:text-18"
            style={{ maxWidth: 600 }}
            dangerouslySetInnerHTML={{ __html: content.data.section_1_content }}
          />
        </div>
      </div>
      <section className="pb-40 pt-60 md:pt-120 md:pb-120">
        <div className="container items-center justify-between md:flex">
          <div
            className="block mb-20 font-normal text-center md:hidden text-24 inner-lineHeight-1"
            dangerouslySetInnerHTML={{
              __html: content.data.section_2_title,
            }}
          />
          <div className="block pl-30 pr-30 md:hidden">
            <img
              src={imageUrl(content.data.section_2_image.path)}
              {...content.data.section_2_image.meta}
              width="100%"
            />
          </div>
          <div className="relative -mt-60 md:mt-0 md:w-6/12 pl-60 pr-60 md:pl-0 md:pr-0 md:-mr-220">
            <div className="p-20 md:pt-40 md:pb-40 md:pl-60 md:pr-80 bg-lightGrey">
              <div
                className="hidden font-normal text-56 mb-50 md:block inner-lineHeight-1"
                style={{ lineHeight: 1.1 }}
                dangerouslySetInnerHTML={{
                  __html: content.data.section_2_title,
                }}
              />
              <div
                className="font-normal md:font-thin text-12 md:text-18 inner-lineHeight-1-5"
                dangerouslySetInnerHTML={{
                  __html: content.data.section_2_content,
                }}
              />

              <a
                href={content.data.section_2_button}
                target="_blank"
                className="inline-block font-normal border-b  md:font-thin md:inline-block mt-60 text-16 md:text-21"
              >
                SMK NU BANAT
              </a>
            </div>
          </div>
          <div className="hidden md:block md:w-8/12">
            <img
              src={imageUrl(content.data.section_2_image.path)}
              {...content.data.section_2_image.meta}
              width="100%"
            />
          </div>
        </div>
      </section>
      <section className="pt-40 pb-20 md:pt-80 bg-lightGrey">
        <div className="container">
          <div className="mb-40 font-normal text-center text-24 md:text-left md:text-56 md:mb-60">
            {lang ? "Penghargaan" : "Awards"}
          </div>
          <div className="text-center md:flex md:text-left">
            <div className="flex-wrap px-40 md:px-0 md:w-8/12 md:flex">
              {(awards.data?.entries || []).map((d: any, i: number) => (
                <div key={i} className="md:w-6/12 md:pr-50 mb-30 md:mb-60">
                  <div className="font-bold text-12 md:text-18">{d.title}</div>
                  <div className="font-normal md:font-thin text-12 md:text-18">
                    {d.content}
                  </div>
                </div>
              ))}
            </div>
            <div className="w-4/12"></div>
          </div>
        </div>
      </section>
      <section className="pt-60 md:pt-120 pb-60 md:pb-120">
        <div className="container">
          <div className="font-normal text-center text-24 md:text-left md:text-56">
            {lang ? "Temui Kami di Instagram" : "We Are on Instagram"}
          </div>
          <div className="justify-between hidden font-normal md:font-light md:flex text-18">
            <div>{lang ? "Ikuti Kami" : "Follow Us"}</div>
            <a target="_blank" href={content.data.Instagram_url}>
              <Right />
            </a>
          </div>
          <div className="pl-30 pr-30 md:pl-0 md:pr-0 mt-50 grid grid-flow-col gap-10 md:gap-20">
            {(content.data.instagram_gallery || []).map((d: any, i: number) => (
              <div key={i}>
                <img src={imageUrl(d.path)} {...d.meta} />
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
}

import { useEffect, useMemo, useState } from "react";
import Cookies from "js-cookie";

export const API_URL = "https://zelmira.id/api";
export const BASE_URL = "https://zelmira.id/";
// export const BASE_URL = "https://4sirteameyesonly.zelmira.id/";
// export const API_URL = "https://4sirteameyesonly.zelmira.id/api";

let MemCache: any = {};

export function imageUrl(path: string) {
  return BASE_URL + path;
}

export function getCookie(name: string) {
  return Cookies.get(name);
}

export default function useContent(
  url: string,
  params: any = {}
): { loading: boolean; data: any } {
  const [state, setState] = useState({
    loading: true,
    data: undefined,
  });

  const newParams = useMemo(() => {
    return params;
  }, [params]);

  const path = encodeURI(`${API_URL}${url}${JSON.stringify(newParams)}`);
  const isCached = MemCache[path];

  useEffect(() => {
    if (isCached) {
      setState({ loading: false, data: isCached });
      return;
    }

    setState({ loading: true, data: undefined });
    if (getCookie("lang") && newParams?.body) {
      newParams.body = JSON.parse(newParams?.body);
      newParams.body.lang = getCookie("lang");
      newParams.body = JSON.stringify(newParams.body);
    }
    fetch(API_URL + url, {
      method: newParams?.body ? "post" : "get",
      headers: {
        "Content-Type": "application/json",
      },
      ...newParams,
    })
      .then(async (res) => {
        const json = await res.json();
        MemCache[path] = json;
        let newJson = json;
        if (getCookie("lang")) {
          Object.keys(json).forEach(function (key) {
            if (key.slice(-3) === "_" + getCookie("lang")) {
              newJson[key.slice(0, -3)] = json[key];
            }
          });
          if (newJson?.entries) {
            newJson.entries = newJson.entries.map((d: any) => {
              let newD = d;
              Object.keys(d).forEach((key) => {
                if (key.slice(-3) === "_" + getCookie("lang")) {
                  newD[key.slice(0, -3)] = d[key];
                }
              });
              return newD;
            });
          }
        }
        setState({ loading: false, data: newJson });
      })
      .catch((err) => {
        setState({ loading: false, data: undefined });
      });
  }, [url, isCached]);

  return state;
}

import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { ReactComponent as Right } from "./svg/chevron-right-black.svg";
import useContent, { getCookie, imageUrl } from "./useContent";
import CarouselCollection from "./components/CarouselCollection";
import Product from "./components/Product";

export default function Collections() {
  const params: any = useParams();

  const content = useContent("/api/collections/get/product_collections", {
    body: JSON.stringify({
      filter: { published: true, _id: params.id },
      limit: 1,
    }),
  });
  const types = useContent("/api/collections/get/product_type", {
    body: JSON.stringify({}),
  });
  const products = useContent("/api/collections/get/product", {
    body: JSON.stringify({
      filter: {
        published: true,
        "category._id": params.id,
      },
      limit: 6,
    }),
  });

  console.log(content);

  const lang = getCookie("lang");

  const collections = useContent("/api/collections/get/product_collections", {
    body: JSON.stringify({}),
  });

  useEffect(() => {
    document.getElementsByTagName("body")[0].className = content.data
      ?.entries[0]?.isDarkMenuLogo
      ? "logo-dark-menu-dark"
      : "logo-white-menu-white";
  }, [content]);

  if (content.loading || !content.data?.entries) {
    return <div className="min-h-screen" />;
  }

  const item = content.data?.entries[0];

  if (!item) {
    return <div className="min-h-screen" />;
  }

  return (
    <div>
      <div
        className="relative flex items-center justify-center bg-center bg-cover h-400 md:h-screen"
        style={{ backgroundImage: `url(${imageUrl(item.banner.path)})` }}
      >
        <div className="container flex justify-center">
          <div
            className="font-normal text-center text-white md:font-thin text-18 md:text-56"
            dangerouslySetInnerHTML={{ __html: item.banner_caption }}
          />
        </div>
      </div>
      <section className="container relative items-end md:flex mb-30 md:mb-70 md:-mt-110">
        <div className="md:w-5/12 pt-30 md:pt-0 md:pl-0 md:pr-0 pl-50 pr-50">
          <div className="font-normal md:font-light text-12 md:text-18 md:mb-80">
            {lang ? "Koleksi " + item.name : item.name + " Collection"}
          </div>
          <div
            className="font-normal md:pr-40 text-24 md:text-56 inner-lineHeight-1.2"
            style={{ lineHeight: 1 }}
            dangerouslySetInnerHTML={{ __html: item.section_1_title }}
          />
        </div>
        <div className="w-full mt-30 md:mt-0 pl-30 pr-30 md:pr-0 md:pl-40 md:w-7/12">
          <img src={imageUrl(item.section_1_top_image.path)} width="100%" />
        </div>
      </section>
      <section className="container md:mb-70">
        <img src={imageUrl(item.section_1_bottom_image.path)} width="100%" />
      </section>
      <section className="container md:flex mb-30 md:mb-120">
        <div className="hidden w-7/12 pr-15 md:block">
          <div>
            <img src={imageUrl(item.section_2_top_image.path)} width="100%" />
          </div>
          <div className="mt-30">
            <img
              src={imageUrl(item.section_2_bottom_image.path)}
              width="100%"
            />
          </div>
        </div>
        <div className="w-full md:w-5/12 pl-30 pr-30 md:pl-0 md:pr-0 md:pl-15">
          <div className="relative md:-ml-110 -mt-30 md:mt-110 md:pt-80 md:pb-80 md:pl-60 p-30 md:pr-80 bg-lightGrey">
            <div className="mb-20 font-normal md:font-light text-24 md:text-18 md:mb-50">
              {lang ? "Tentang Koleksi" : "About the Collection"}
            </div>
            <div
              className="font-normal md:font-thin text-12 md:text-18 inner-lineHeight-1-5"
              style={{ maxWidth: 400 }}
              dangerouslySetInnerHTML={{ __html: item.section_2_content }}
            />
          </div>
          <div className="hidden mt-30 md:block">
            <img src={imageUrl(item.section_2_right_image.path)} width="100%" />
          </div>
        </div>
      </section>
      <div className="md:hidden mb-50">
        <div className="flex">
          <div className="w-6/12">
            <img src={imageUrl(item.section_2_top_image.path)} width="100%" />
          </div>
          <div className="w-6/12">
            <img src={imageUrl(item.section_2_right_image.path)} width="100%" />
          </div>
        </div>
        <div className="pl-30 pr-30 mt-30">
          <img src={imageUrl(item.section_2_bottom_image.path)} width="100%" />
        </div>
      </div>
      <div className="container items-end justify-between hidden text-center md:text-left md:flex pl-30 pr-30 md:pl-0 md:pr-0 mb-50">
        <div className="font-normal text-18 md:text-56">
          {lang
            ? "Koleksi Lengkap " + item.name
            : "Full " + item.name + " Collection"}
        </div>
        <div className="pb-10 font-normal md:font-thin">
          <Link
            to={`/all-collections/${params.id}`}
            className="flex items-center"
          >
            {lang ? "Lihat Koleksi" : "See Collection"}{" "}
            <Right className="ml-10" />
          </Link>
        </div>
      </div>
      <div className="flex justify-center md:hidden mb-30">
        <Link
          to={`/all-collections/${params.id}`}
          className="flex items-center border-b text-24"
        >
          {lang ? "Koleksi Lengkap" : "See Full Collection"}
        </Link>
      </div>
      <div className="pl-30 pr-30 md:pl-0 md:pr-0">
        <div className="container grid grid-cols-2 md:grid-cols-3 gap-20">
          {(products.data?.entries || []).map((d: any, i: number) => {
            return <Product types={types} data={d} key={i} />;
          })}
        </div>
        {(collections.data || []).length > 1 && (
          <div className="hidden bg-lightGrey md:block pt-60 pb-60">
            <div className="container mb-20">
              <div className="font-normal text-center md:font-light mb-60 text-56">
                {lang ? "Koleksi Lainnya" : "Other Collection"}
              </div>
              <CarouselCollection data={collections.data} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

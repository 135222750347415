import { useEffect, useState } from "react";
import { ReactComponent as Right } from "./svg/chevron-right-black.svg";
import useContent, { getCookie, imageUrl, API_URL } from "./useContent";

export default function Contact() {
  const content: any = useContent("/api/singletons/get/page_contact", {
    body: JSON.stringify({}),
  });
  const lang = getCookie("lang");
  const [showModal, setShowModal] = useState("");
  const [formData, setFormData] = useState<any>({
    fullname: "",
    email: "",
    subject: "",
    message: "",
    validate: false,
    loading: false,
  });
  useEffect(() => {
    document.getElementsByTagName("body")[0].className =
      window.innerWidth < 768 ? "logo-dark-menu-dark" : "logo-white-menu-dark";
  }, []);

  const setForm = (name: string, val: string) => {
    setFormData((p: any) => ({ ...p, [name]: val }));
  };

  const onSubmit = () => {
    if (!formData.validate) {
      setFormData((p: any) => ({ ...p, validate: true, loading: false }));
      return;
    }
    setFormData((p: any) => ({ ...p, loading: true }));
    if (
      formData.fullname &&
      formData.email &&
      formData.subject &&
      formData.message
    ) {
      fetch(
        API_URL +
          "/api/forms/submit/reservation?token=c918139ceb7fa5013471f4505f9cf8",
        {
          method: "post",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            form: formData,
          }),
        }
      )
        .then(() => setShowModal("SUCCESS"))
        .catch(() => setShowModal("ERROR"))
        .finally(() => setFormData((p: any) => ({ ...p, loading: false })));
    }
  };

  const validate = (key: string) => {
    if (!formData.validate) return "";
    if (formData.validate && formData[key]) return "";
    return (
      <div
        style={{ color: "red", fontSize: 12, marginTop: 5 }}
        className="font-light"
      >
        {lang ? "Wajib diisi." : "Please fill out this field."}
      </div>
    );
  };

  if (content.loading && !content.data) {
    return <div className="min-h-screen" />;
  }

  return (
    <div className="flex md:min-h-screen">
      <div
        className="hidden w-6/12 bg-center bg-cover md:block"
        style={{ backgroundImage: `url(${imageUrl(content.data.image.path)})` }}
      ></div>
      <div className="w-full pl-30 pr-30 md:w-6/12 bg-lightGrey md:pl-80 md:pr-150 pt-120">
        <div>
          <div
            className="mb-40 font-normal inner-lineHeight-1 text-center md:text-left text-18 md:text-56"
            dangerouslySetInnerHTML={{ __html: content.data.title }}
          />
          <div className="mb-30">
            <input
              placeholder={lang ? "Nama Lengkap" : "Full Name"}
              onChange={(e) => setForm("fullname", e.target.value)}
              className="w-full pt-10 pb-10 font-normal placeholder-black bg-transparent border-b border-black rounded-none outline-none appearance-none md:font-thin text-18"
            />
            {validate("fullname")}
          </div>
          <div className="mb-30">
            <input
              placeholder="Email"
              onChange={(e) => setForm("email", e.target.value)}
              className="w-full pt-10 pb-10 font-normal placeholder-black bg-transparent border-b border-black rounded-none outline-none appearance-none md:font-thin text-18"
            />
            {validate("email")}
          </div>
          <div className="mb-30">
            <input
              placeholder={lang ? "Subjek" : "Subject"}
              onChange={(e) => setForm("subject", e.target.value)}
              className="w-full pt-10 pb-10 font-normal placeholder-black bg-transparent border-b border-black rounded-none outline-none appearance-none md:font-thin text-18"
            />
            {validate("subject")}
          </div>
          <div className="mb-30">
            <div className="w-full pt-10 pb-10 font-normal md:font-thin text-18">
              {lang ? "Pesan" : "Message"}
            </div>
            <textarea
              rows={5}
              onChange={(e) => setForm("message", e.target.value)}
              className="w-full p-10 font-normal placeholder-black bg-transparent border border-black rounded-none outline-none appearance-none md:font-thin text-18"
            />
            {validate("message")}
          </div>
          <div>
            {formData.loading ? (
              <button
                disabled
                className="flex items-center font-normal md:font-thin mb-30 text-18"
              >
                loading <Right className="ml-10" />
              </button>
            ) : (
              <button
                onClick={() => onSubmit()}
                className="flex items-center font-normal md:font-thin mb-30 text-18"
              >
                {lang ? "Kirim" : "Send"} <Right className="ml-10" />
              </button>
            )}
          </div>
        </div>
      </div>
      {showModal === "SUCCESS" && (
        <div className="fixed top-0 bottom-0 left-0 right-0 z-50 flex items-center justify-center text-center text-white bg-black bg-opacity-75">
          <div
            className="relative p-40 bg-blue"
            style={{ maxWidth: 400, width: "100%" }}
          >
            <div className="mb-20 font-normal md:font-light text-32">
              {lang ? "Terima Kasih" : "Thank You"}
            </div>
            <p
              className="mb-20 font-normal md:font-light text-16"
              dangerouslySetInnerHTML={{
                __html: lang
                  ? "Kami sudah menerima pesan Anda. <br />Kami akan merespon secepatnya."
                  : "We have received your message. <br /> We will get back to you soon!",
              }}
            />
            <button
              onClick={() => setShowModal("")}
              className="absolute top-0 right-0 p-20"
            >
              <svg
                width={26}
                height={24}
                viewBox="0 0 26 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="w-20 md:w-25"
              >
                <line
                  x1="0.838811"
                  y1="23.3178"
                  x2="23.4662"
                  y2="0.690398"
                  stroke="white"
                  strokeWidth="1.5"
                />
                <line
                  x1="1.89947"
                  y1="0.690373"
                  x2="24.5269"
                  y2="23.3178"
                  stroke="white"
                  strokeWidth="1.5"
                />
              </svg>
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
